import React from "react";
import TextButton from "../../../templates/component/buttons/textButton";

const HomePageAddButton = () =>{

    return(
        <>
            <TextButton />
        </>
    )
}

export default HomePageAddButton;