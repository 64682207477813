import React from "react";
import TextButton from "../../../templates/component/buttons/textButton";

const BlogAddButton = () =>{

    return(
        <>
            <TextButton />

        </>
    )
}

export default BlogAddButton;