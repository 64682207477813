import Input from "./input";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import Checkbox from "./CheckBox";
import DatePicker from "./DatePicker";
import Button from "./Button";
import MultiSelect from "./MultiSelect";
import Search from "./searchBar";
import Image from "./image";
import DateTimePicker from "./DateTimePicker";

const Controls = {
    Input,
    RadioGroup,
    Select,
    Checkbox,
    DatePicker,
    Button,
    MultiSelect,
    Search,
    Image,
    DateTimePicker,
}

export default Controls;